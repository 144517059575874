import { articleLayoutConfig as WLArticleLayoutConfig } from '@hubcms/brand-wl';
import { AuthMethod } from '@hubcms/domain-auth';
import { GtmCode } from '@hubcms/domain-tracking';
import { ImageFormat } from '@hubcms/domain-images';
import { CustomConsentType } from '@hubcms/domain-consent';
import { EPaperLinkHrefTarget, EPaperLinkImage } from '@hubcms/domain-navigation';
import ePaperImage from './e-paper.svg';

export { de as dateFnsLocale } from 'date-fns/locale/de';
export const locale = 'de-DE';
export const timeZone = 'Europe/Berlin';
export const archiveTitle = 'Archiv';
export const gtmCodes: GtmCode[] = [{ code: 'GTM-NJVXBNHL', isParent: true }];
export const brandCode = 'az';
export const adunitBrandCode = 'az';
export const b2bUrlBrandCode = 'az';
export const b2bDMPMetaTagBrandCode = '';
export const footerLogo = 'variations.brand-square-icon-name-horizontal-white';
export const headerDateFormat = 'eeee, dd.MM.yyyy';
export const hasDividerInResultListLg = false;
export const loginLabel = 'Anmelden';
export const logoutLabel = 'Abmelden';
export const authorPrefix = 'autoren/';
export const searchPrefix = 'suche/';
export const tagsPrefix = 'themen/';
export const authMethod: AuthMethod = 'oauth';
export const enableTextToSpeech = true;
export const weatherSectionPage = '/wetter/';
export const defaultTeaserImageRatio: ImageFormat = 'threeTwo';

export const articleLayoutConfig = {
  ...WLArticleLayoutConfig,
  heroblockPremiumIconLocation: 'heading',
  extraPageImageClass: 'contentWidthBleed',
}; // articleV1 param

export const heroBlockIntroProps = {
  size: 'lg',
  weight: 'strong',
};
export const hasPullQuoteIcon = true;

export const tickarooClientId = '5bb37700af4b55c54aefda22';
export const customConsent: CustomConsentType = 'zweiwegenmodel';
export const enableMySection = true;
export const hasAdsAbovePaywalledArticleHero = false; // articleV1 param
export const enablePersonalizedArticleList = true;
export const weatherIconSize = 'md';
export const ePaperLinkLabel = 'E-Paper';
export const ePaperLinkHref = '/e-paper-kiosk';
export const ePaperLinkImage: EPaperLinkImage = {
  src: ePaperImage,
  height: 24,
  width: 24,
};
export const ePaperLinkHrefTarget: EPaperLinkHrefTarget = '_self';
export const domain = 'aachener-zeitung.de';
export const articleCommentsType = 'coral_talk';
export const subscriptionButtonAppearance = 'secondary';
export const enableGrapeshot = true;
export const articleLayoutVersion = 'v2'; // articleV1 param
export const heroBlockSocialIconSize = 'lg';
export const isAppTopicsMenuEnabled = false;
