import type { GTMEvent, GTMGlobal, Event } from '@hubcms/domain-gtm';
import {
  addToQueue,
  shouldEventBeQueued,
  getEventsToSend,
  isEventWithConditions,
  removeEventsFromQueue,
} from './gtm-event-queue';

declare global {
  interface Window {
    dataLayer?: GTMEvent[];
    google_tag_manager?: GTMGlobal;
  }
}

type Options = {
  timeout?: number;
};

/**
 * Ensure that sendGTMEvent resolves. This is important for critical events — such as login and logout — where we want to make sure that resolve is called.
 *
 * 1. The window is not available. We are in a server environment.
 * 2. The dataLayer is not available. GTM could be available but without the dataLayer this is irrelevant.
 * 3. GTM is not loaded. This doesn't mean that we can't push to the dataLayer. It also doesn't mean that GTM won't be loaded in the future.
 * 4. GTM is loaded, the dataLayer is available.
 *
 * Note that every GTM container will fire the eventCallback. This means that if you have multiple containers, the eventCallback will be called multiple times.
 * Because we don't know which container will be responsible for handling the event, we have to wait for all containers to fire the eventCallback before resolving.
 */
export async function sendGTMEvents(events: Event | Event[], options: Options = {}): Promise<void> {
  if (!has(window)) {
    return;
  }

  if (Array.isArray(events)) {
    await Promise.all(events.map(e => sendGTMEvents(e, options)));
    return;
  }

  // Rename for clarity
  const event = events;

  if (!has(window.dataLayer)) {
    return;
  }

  if (isEventWithConditions(event) && shouldEventBeQueued(event, window.dataLayer)) {
    await addToQueue(event);
    return;
  }

  const eventsToSend = getEventsToSend(window.dataLayer, event);
  const queuedEventsToSend = eventsToSend.slice(1);
  removeEventsFromQueue(queuedEventsToSend);

  if (!has(window.google_tag_manager)) {
    window.dataLayer.push(...eventsToSend);
    eventsToSend.forEach(event => {
      if (typeof event.resolve === 'function') {
        event.resolve();
      }
    });
    return;
  }

  const props = Object.keys(window.google_tag_manager);
  const ids = new Set(props.filter(prop => prop.startsWith('GTM-')));

  await new Promise<void>(resolve => {
    window.dataLayer!.push(
      ...eventsToSend.map(event => ({
        ...event,
        eventCallback(id: string) {
          ids.delete(id);
          if (ids.size === 0) {
            // Event that was previously queued
            if (typeof event.resolve === 'function') {
              event.resolve();
            }
            resolve();
          }
        },
        eventTimeout: options?.timeout,
      })),
    );
  });
}

function has<T>(value?: T): value is T {
  return typeof value !== 'undefined';
}
