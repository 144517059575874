import type { GTMEvent, Event, EventConditions } from '@hubcms/domain-gtm';
import { Deferred } from '@hubcms/utils-promise';

type EventWithConditions = Omit<Event, 'conditions'> & {
  conditions: EventConditions;
};

type DeferredEventWithConditions = EventWithConditions & {
  resolve: () => void;
};

let queuedEvents: readonly DeferredEventWithConditions[] = [];

export function isEventWithConditions(event: Event | EventWithConditions): event is EventWithConditions {
  return 'conditions' in event;
}

export function addToQueue(event: EventWithConditions) {
  const { resolve, promise } = new Deferred<void>();
  queuedEvents = [
    ...queuedEvents,
    {
      ...event,
      resolve,
    },
  ];
  return promise;
}

export function shouldEventBeQueued(event: EventWithConditions, sentEvents: GTMEvent[]) {
  return sentEvents.every(sentEvent => sentEvent.event !== event.conditions.afterEvent);
}

export function getEventsToSend(sentEvents: GTMEvent[], event: Event | EventWithConditions): GTMEvent[] {
  if (isEventWithConditions(event) && shouldEventBeQueued(event, sentEvents)) {
    return [];
  }
  const { conditions, ...eventWithoutConditions } = event;
  return [
    eventWithoutConditions,
    ...queuedEvents
      .filter(queuedEvent => !shouldEventBeQueued(queuedEvent, [...sentEvents, eventWithoutConditions]))
      .map(({ conditions, ...event }) => event),
  ];
}

export function removeEventsFromQueue(gtmEventsToRemove: GTMEvent[]) {
  const eventsToRemove = gtmEventsToRemove.map(gtmEventToRemove => gtmEventToRemove.event).filter(Boolean);
  queuedEvents = queuedEvents.filter(queuedEvent => !eventsToRemove.includes(queuedEvent.event));
}

// This function only exists for test purposes. Not ideal, I know
export function getEventsInQueue() {
  return queuedEvents;
}
